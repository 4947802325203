import React from "react"
import { Box, Flex } from "@theme-ui/components"
import { Link } from "./link"
import {
  getArticleCategoryPath,
  getBlogPath,
  getHomePath,
  getPagePath,
  getStoriesPath,
  getStoryCategoryPath,
} from "../utils/path"
import { i18nContext } from "../context/i18nContext"

const Breadcrumbs = ({ page }) => {
  function renderSwitch(page) {
    switch (page.model.apiKey) {
      case "article":
        return <ArticleBreadcrumbs page={page} />
      case "story":
        return <StoryBreadcrumbs page={page} />
      default:
        return <PageBreadcrumbs page={page} />
    }
  }

  const PageBreadcrumbs = ({ page }) => (
    <i18nContext.Consumer>
      {t => (
        <List>
          <Item>
            <Link
              variant="breadcrumbs"
              to={getHomePath(page.locale)}
              sx={{ display: "inline !important" }}
            >
              Home
            </Link>
          </Item>
          {page.treeParent && page.treeParent.treeParent && (
            <Item dir={t.locale === "he" ? "rtl" : "auto"}>
              <Link
                variant="breadcrumbs"
                to={getPagePath(page.treeParent.treeParent)}
              >
                {page.treeParent.treeParent.title ||
                  page.treeParent.treeParent.name}
              </Link>
            </Item>
          )}
          {page.treeParent && (
            <Item dir={t.locale === "he" ? "rtl" : "auto"}>
              <Link variant="breadcrumbs" to={getPagePath(page.treeParent)}>
                {page.treeParent.title}
              </Link>
            </Item>
          )}
          <Item dir={t.locale === "he" ? "rtl" : "auto"}>{page.title}</Item>
        </List>
      )}
    </i18nContext.Consumer>
  )

  const ArticleBreadcrumbs = ({ page }) => (
    <List>
      <LargeItem>
        <Link href={getBlogPath(page.locale)}>Blog</Link>
      </LargeItem>
      {page.category && (
        <LargeItem>
          <Link href={getArticleCategoryPath(page.category, page.locale)}>
            {page.category.title}
          </Link>
        </LargeItem>
      )}
    </List>
  )

  const StoryBreadcrumbs = ({ page }) => (
    <List>
      <LargeItem>
        <Link href={getStoriesPath(page.locale)}>AB Stories</Link>
      </LargeItem>
      {page.category && (
        <LargeItem>
          <Link href={getStoryCategoryPath(page.category, page.locale)}>
            {page.category.title}
          </Link>
        </LargeItem>
      )}
    </List>
  )

  return renderSwitch(page)
}

const List = props => {
  return (
    <Flex
      {...props}
      sx={{
        flexDirection: "row",
        margin: 0,
        padding: 0,
        listStyle: "none",
      }}
      as="ul"
    />
  )
}

const Item = props => {
  return (
    <Box
      {...props}
      sx={{
        marginRight: 2,
        fontSize: 0,
        textTransform: "uppercase",
        color: "#dedede",
        "&::after": {
          content: '"|"',
          marginLeft: 2,
          display: ["inline"],
        },
        "&:last-child": {
          marginRight: 0,
          "&::after": {
            display: "none",
          },
        },
      }}
      as="li"
    />
  )
}

const LargeItem = props => {
  return (
    <Box
      {...props}
      sx={{
        marginRight: 2,
        fontSize: [0, 2],
        textTransform: "uppercase",
        color: "#dedede",
        "&::after": {
          content: '"|"',
          marginLeft: 2,
          display: ["inline"],
        },
        "&:last-child": {
          marginRight: 0,
          "&::after": {
            display: "none",
          },
        },
      }}
      as="li"
    />
  )
}

export default Breadcrumbs
